<template>
    <div>
        <Alert />
        <div
            class="flex items-center justify-center"
            :style="`background-image: url(${require('@/assets/images/login-bg.jpg')})`"
            style="background-size: cover; height: 100vh"
        >
            <div class="w-50-l w-90">
                <!-- Simplebks logo -->
                <div class="tc pb4">
                    <img src="../../assets/images/simplebks-logo.png" style="width: 180px; height: auto" alt="simplebks-logo" />
                </div>
                <!-- Simplebks logo -->

                <!-- login/Input -->
                <div style="background-color: white">
                    <form class="center pa5-l pa3" @submit.prevent="handleCreateProfile">
                        <h2 class="tc">Welcome</h2>
                        <!--                    <div class="flex flex-column pb3">-->
                        <!--                        <label class="pb2">Email</label>-->
                        <!--                        <input-->
                        <!--                            class="form-sign"-->
                        <!--                            type="email"-->
                        <!--                            name="email"-->
                        <!--                            placeholder="beezo@amazon.com"-->
                        <!--                            id="email"-->
                        <!--                            required-->
                        <!--                            v-model="state.email"-->
                        <!--                        />-->
                        <!--                    </div>-->

                        <div class="flex justify-between pb3" style="gap: 1rem">
                            <div class="flex flex-column pb3 w-100">
                                <label class="pb2">First Name</label>
                                <input
                                    class="form-sign"
                                    type="text"
                                    name="firstName"
                                    placeholder="eg. Jane"
                                    id="firstName"
                                    required
                                    v-model="state.firstName"
                                />
                            </div>

                            <div class="flex flex-column pb3 w-100">
                                <label class="pb2">Last Name</label>
                                <input
                                    class="form-sign"
                                    type="text"
                                    name="lastName"
                                    placeholder="e.g John"
                                    id="lastName"
                                    required
                                    v-model="state.lastName"
                                />
                            </div>
                        </div>

                        <div class="flex" style="gap: 1rem">
                            <div class="flex flex-column pb3 w-100">
                                <label class="pb2">Create New Password</label>
                                <input
                                    class="form-sign"
                                    type="password"
                                    name="password"
                                    placeholder="*************"
                                    id="password"
                                    required
                                    v-model="state.password"
                                />
                            </div>

                            <div class="flex flex-column pb3 w-100">
                                <label class="pb2">Confirm Password</label>
                                <input
                                    class="form-sign"
                                    type="password"
                                    name="password2"
                                    placeholder="*************"
                                    id="password2"
                                    required
                                    v-model="state.password2"
                                />
                            </div>
                        </div>
                        <!--                    <div class="flex justify-between">-->
                        <!--                        <label class="container">-->
                        <!--                            <input type="checkbox" checked="checked" />Remember me-->
                        <!--                            <span class="checkmark"></span>-->
                        <!--                        </label>-->
                        <!--                        <div style="font-size: 14px"><a href="">Forget Password?</a></div>-->
                        <!--                    </div>-->
                        <button class="mt3 w-100 submit-btn" type="submit" :disabled="state.disableButton">
                            {{ state.disableButton ? 'Joining, please wait...' : 'Join Your Team' }}
                        </button>
                        <!--                    <div class="tc mt3">-->
                        <!--                        Don't have account? <a href="Signup-page.html"><b>Register</b></a>-->
                        <!--                    </div>-->
                    </form>
                </div>
                <!-- login/Input -->
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Alert from '@/components/Alert'

export default {
    name: 'JoinTeam',
    components: { Alert },

    setup() {
        const router = useRouter()
        const store = useStore()
        const { query } = router?.currentRoute?.value

        const initialState = () => ({
            disableButton: false,
            firstName: '',
            lastName: '',
            // email: '',
            password: '',
            password2: '',
        })
        const state = reactive(initialState())

        const handleCreateProfile = () => {
            state.disableButton = true

            const payload = {}

            const formData = {
                firstName: state.firstName,
                lastName: state.lastName,
                // email: state.email,
                password: state.password,
                password2: state.password2,
            }

            payload.formData = formData
            payload.query = query

            store
                .dispatch('Settings/createProfileAndJoinTeam', payload)
                .then((resp) => {
                    if (resp) {
                        state.disableButton = false
                        router.push({ name: 'SignIn' })
                    } else {
                        state.disableButton = false
                    }
                })
                .catch(() => {
                    state.disableButton = false
                })
        }

        onMounted(() => store.dispatch('Settings/validateTeamMemberInvite', query))

        return { state, handleCreateProfile }
    },
}
</script>

<style scoped></style>
